import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Fullscreen, Groups } from '@mui/icons-material';
import {
  Button,
  Card,
  Collapse,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { FiCalendar, FiHash } from 'react-icons/fi';
import { AuthContext } from '../../App';
import { addAIForecast } from '../../store/slices/autoSlice';
import {
  selectLatestOutcomeBelief,
  selectOutcomeBeliefChange
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import ForecastSubmissionModal from '../modals/ForecastSubmissionModal';
import GenerateQuestionsModal from '../modals/GenerateQuestionsModal';
import ForecastDashboard from '../other/charts/ForecastDashboard';
import Markdown from '../other/Markdown';
import OutcomeStatusText from '../other/OutcomeStatusText';

import { useDispatch } from 'react-redux';
import ErrorModal from '../modals/ErrorModal';
import SuccessModal from '../modals/SuccessModal';

export default function OutcomeCardQuestionView({ outcome }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForecastSubmissionModal, setShowForecastSubmissionModal] =
    useState(false);

  const forecastOverruleSettings = useSelector((state) =>
    selectSettingByName(state, 'Forecast Submission Override')
  );
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const autoQuestionsSetting = useSelector((state) =>
    selectSettingByName(state, 'AutoQuestions Toggle')
  );

  const aiForecastSetting = useSelector((state) =>
    selectSettingByName(state, 'AI Forecast Toggle')
  );

  const latestBelief = useSelector((state) =>
    selectLatestOutcomeBelief(state, outcome.id)
  );

  const beliefChange = useSelector((state) =>
    selectOutcomeBeliefChange(state, outcome.id)
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [dashboardOpen, setDashboardOpen] = useState(true);
  const { userData } = useContext(AuthContext);
  const usersData = useSelector((state) => state.users.entities);
  const [showGenerateQuestionsModal, setShowGenerateQuestionsModal] =
    useState(false);

  const dispatch = useDispatch();
  const createAIForecast = async (e) => {
    e.stopPropagation();
    setIsAIForecastButtonDisabled(true);
    try {
      const token = localStorage.getItem('auth_token');
      let payload = { outcome_id: outcome.id, auth_token: token };
      await dispatch(addAIForecast(payload))
        .unwrap()
        .then((response) => {
          if (response.status === 'success') {
            setSuccessMessage('AI Forecasting successfully received.');
            setShowSuccess(true);
          } else {
            setErrorMessage(
              `Please wait ${response.data.waitTime} minutes before trying again.`
            );
            setShowError(true);
          }
        });
    } catch (error) {
      setErrorMessage(`Failed to add AI forecasts: ${error.message}`);
      setShowError(true);
    }
  };

  const [isAIForecastButtonDisabled, setIsAIForecastButtonDisabled] =
    useState(false);
  useEffect(() => {
    checkButtonStatus();
  }, [outcome]);

  const minutesSinceOutcomeAIForecastButtonPressed = () => {
    if (outcome?.ai_forecast_button_pressed) {
      return moment().diff(
        moment(outcome?.ai_forecast_button_pressed, 'YYYY-MM-DDThh:mm:ss'),
        'minutes'
      );
    } else {
      return 999999999;
    }
  };

  const checkButtonStatus = async () => {
    try {
      if (minutesSinceOutcomeAIForecastButtonPressed() >= 10) {
        setIsAIForecastButtonDisabled(false);
      } else {
        setIsAIForecastButtonDisabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBorderColour = () => {
    if (outcome.statuses.includes('Forecasting')) {
      return theme.palette.statuses.mid3;
    } else if (outcome.statuses.includes('Evaluation')) {
      return theme.palette.statuses.mid2;
    } else if (outcome.statuses.includes('Generation')) {
      return theme.palette.statuses.mid1;
    } else if (outcome.statuses.includes('Closed')) {
      return theme.palette.statuses.mid4;
    } else {
      return theme.palette.statuses.mid5;
    }
  };

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <div className="OutcomeCardQuestionView">
        <Card
          sx={{
            boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
            borderLeftWidth: '5px',
            borderLeftColor: getBorderColour()
          }}
          className={`m-5 px-5 py-4 break-words`}>
          <div className="OutcomeText font-medium max-h-36 overflow-y-auto">
            <div className="flex justify-between">
              <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                <span className="whitespace-pre-line">{outcome.title}</span>
              </Typography>
              <div className="flex flex-row">
                {outcome.statuses.map((status, index) => (
                  <Typography
                    key={index}
                    sx={{
                      borderColor: getOutcomeStatusColour(status),
                      backgroundColor: getOutcomeStatusColour(status),
                      py: 0,
                      px: 0.5,
                      fontSize: '0.95rem',
                      mx: 0.6
                    }}
                    className={`flex justify-center items-center border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                    {status}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
          <div className="OutcomeInfo flex flex-wrap items-center justify-start">
            {outcome.statuses.map((status, index) => (
              <OutcomeStatusText
                status={status}
                outcome_id={outcome.id}
                key={index}
              />
            ))}
            {(userData.role === 'Admin' ||
              userData.role === 'Moderator' ||
              usernameSettings.active) && (
              <div className="flex items-center">
                <Typography
                  sx={{
                    ml: 0.6,
                    fontSize: '0.95rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  color="text.secondary">
                  Created by
                </Typography>
                <Typography
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/profile/${outcome.created_by.username}`);
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      e.stopPropagation();
                      navigate(`/profile/${outcome.created_by.username}`);
                    }
                  }}
                  sx={{ mx: 0.6, fontSize: '0.95rem' }}
                  className="hover:underline hover:cursor-pointer"
                  color="primary.main">
                  {outcome.created_by.username}
                </Typography>
                <div className="pb-1">
                  <RoleBadgeIcon
                    role={usersData[outcome.created_by.id]?.role}
                  />
                </div>
              </div>
            )}
            {outcome.groups?.length > 0 && (
              <div className="sm:flex hidden items-center p-1">
                <Groups
                  fontSize="inherit"
                  sx={{ mr: 0.6, color: 'text.secondary' }}
                />
                <Typography
                  sx={{
                    mr: 0.6,
                    fontSize: '0.875rem'
                  }}
                  color="text.secondary">
                  <span className="font-bold"></span>
                  {outcome.groups.map((group, index) => (
                    <Link
                      key={group.id}
                      sx={{
                        color: 'text.secondary',
                        textDecoration: 'None',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigate(`/user-groups/${group.id}`);
                      }}>
                      {index > 0 ? ', ' : ''}
                      {group.name}
                    </Link>
                  ))}
                </Typography>
              </div>
            )}
          </div>
          <div className="markdown max-h-72 overflow-y-auto">
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: '1rem',
                p: 1
              }}
              component="div">
              <Markdown description={outcome.description} />
            </Typography>
          </div>
          <div className="sm:flex hidden items-center p-1">
            {(outcome.statuses.includes('Generation') ||
              outcome.statuses.includes('Evaluation')) && (
              <div className="flex items-center mr-2">
                <FiCalendar className="mr-1 mb-0.5" />
                <Typography
                  sx={{
                    mr: 0.6,
                    fontSize: '0.875rem'
                  }}>{`Outcome Ends: ${moment
                  .utc(outcome.end_at)
                  .local()
                  .format('dddd, MMMM Do YYYY')}`}</Typography>
              </div>
            )}
            {(outcome.statuses.includes('Generation') ||
              outcome.statuses.includes('Evaluation')) &&
              outcome.question_submission_limit && (
                <div className="sm:flex items-center mx-4 mr-2 hidden">
                  <FiHash size="20" className="mr-1 mb-0.5" />
                  <Typography
                    sx={{
                      fontSize: '0.875rem'
                    }}>{`The top ${outcome.question_submission_limit} rated Questions will be submitted to the Forecasting Tournament`}</Typography>
                </div>
              )}
          </div>
          <div className="my-2">
            {latestBelief && (
              <div className="flex flex-wrap flex-row">
                <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
                  There is currently a mean{' '}
                  {(latestBelief.belief * 100).toFixed(1)}% belief across the
                  portfolio of questions.
                </Typography>
                <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
                  This indicates belief that the outcome
                </Typography>
                {latestBelief.belief > 0.5 ? (
                  <Typography
                    sx={{ mx: 0, fontSize: '0.95rem' }}
                    color={
                      theme.palette.mode === 'dark'
                        ? latestBelief.belief > 0.75
                          ? 'limegreen'
                          : 'orange'
                        : latestBelief.belief > 0.75
                        ? 'green'
                        : 'orange'
                    }>
                    will happen.
                  </Typography>
                ) : (
                  <Typography
                    sx={{ mx: 0, fontSize: '0.95rem' }}
                    color={
                      theme.palette.mode === 'dark'
                        ? latestBelief.belief > 0.25
                          ? 'orange'
                          : 'tomato'
                        : latestBelief.belief > 0.25
                        ? 'orange'
                        : 'red'
                    }>
                    won't happen.
                  </Typography>
                )}
              </div>
            )}
            {beliefChange !== null && (
              <div className="flex flex-wrap flex-row">
                <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
                  Belief in the outcome has
                </Typography>
                {beliefChange > 0 ? (
                  <Typography
                    sx={{ mx: 0, fontSize: '0.95rem' }}
                    color={
                      theme.palette.mode === 'dark' ? 'limegreen' : 'green'
                    }>
                    increased by {(Math.abs(beliefChange) * 100).toFixed(1)}%
                  </Typography>
                ) : beliefChange < 0 ? (
                  <Typography
                    sx={{ mx: 0, fontSize: '0.95rem' }}
                    color={theme.palette.mode === 'dark' ? 'tomato' : 'red'}>
                    reduced by {(Math.abs(beliefChange) * 100).toFixed(1)}%
                  </Typography>
                ) : (
                  <Typography
                    sx={{ mx: 0, fontSize: '0.95rem' }}
                    color="text.primary">
                    not changed
                  </Typography>
                )}
                <Typography sx={{ mx: 0.6, fontSize: '0.95rem' }}>
                  over the last 24 hours
                </Typography>
              </div>
            )}
          </div>
          {outcome.id && (
            <div className="flex justify-start w-full">
              <Collapse
                in={outcome.statuses.includes('Forecasting') && dashboardOpen}
                sx={{ width: '100%' }}
                className="w-auto">
                <div className="flex items-start w-full">
                  <ForecastDashboard
                    outcomeId={outcome.id}
                    width={'large'}
                    forecastingStartDate={outcome.forecasting_start_date}
                    setDashboardOpen={setDashboardOpen}
                  />
                </div>
              </Collapse>
            </div>
          )}
          {!dashboardOpen && (
            <div className="flex justify-start">
              <Button onClick={() => setDashboardOpen(true)} variant="outlined">
                <Fullscreen />
                Expand Dashboard
              </Button>
            </div>
          )}

          <div className="flex ">
            {outcome.statuses.includes('Evaluation') &&
              outcome.question_submission_limit &&
              ((outcome.created_by.username === userData.username &&
                forecastOverruleSettings?.options.find(
                  (setting) => setting.name === 'outcome owner'
                ).selected === true) ||
                userData.role === 'Admin') && (
                <div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowForecastSubmissionModal(true);
                    }}>
                    Forecast Override
                  </Button>
                </div>
              )}

            {showForecastSubmissionModal && (
              <ForecastSubmissionModal
                close={() => {
                  setShowForecastSubmissionModal(false);
                }}
                outcomeId={outcome.id}
              />
            )}
            {(outcome.created_by.id === userData.id ||
              userData.role === 'Admin') &&
              outcome.statuses.includes('Generation') &&
              autoQuestionsSetting?.active && (
                <div>
                  <Button onClick={() => setShowGenerateQuestionsModal(true)}>
                    Create AI Questions
                  </Button>
                </div>
              )}
            {showGenerateQuestionsModal && (
              <GenerateQuestionsModal
                shown={showGenerateQuestionsModal}
                close={() => {
                  setShowGenerateQuestionsModal(false);
                }}
                outcomeId={outcome.id}
              />
            )}

            {outcome.enable_AI_forecasting === 'True' &&
              (outcome.created_by.username === userData.username ||
                userData.role === 'Moderator' ||
                userData.role === 'Admin') &&
              outcome.statuses.includes('Forecasting') &&
              outcome.forecast_mechanism === 'derived' &&
              aiForecastSetting?.active && (
                <div className="flex items-center mr-2">
                  <Button
                    size="small"
                    className={'items-center'}
                    onClick={(e) => {
                      createAIForecast(e);
                    }}
                    disabled={isAIForecastButtonDisabled}>
                    <span>Add AI Forecast For All Questions</span>
                  </Button>
                </div>
              )}
          </div>
        </Card>
        {showError && (
          <div>
            <ErrorModal
              shown={showError}
              close={() => {
                setShowError(false);
              }}
              errorMessage={errorMessage}
            />
          </div>
        )}
        {showSuccess && (
          <div>
            <SuccessModal
              shown={showSuccess}
              close={() => {
                setShowSuccess(false);
              }}
              successMessage={successMessage}
            />
          </div>
        )}
      </div>
    );
  }
}
