import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography } from '@mui/material';

import "react-datepicker/dist/react-datepicker.css";

import { selectUserPreferences } from '../../../store/slices/userSlice';
import StrategyForm from './StrategyForm';

export default function CreateStrategy({ outcome }) {
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  return (
    <div>
      <Card
        sx={{
          px: userPreferences.outcome_layout === 'card' ? 0.9 : 3.6,
          pt: 1.0,
          pb: 2.0,
          mb: 0.6,
        }}>
        <div className="px-2 m-2 ">
          <Typography sx={{ mt: 0.25, fontSize: '1.4rem' }}>
            Post a Strategy
          </Typography>
        </div>
        <div className='m-2 px-2'>
          Post a strategy describing a route the Outcome Owner could take to achieve their desired outcome. Try to define the specific actions that will be taken.
        </div>
        <StrategyForm outcomeId={outcome.id} />
      </Card>
    </div >
  );
}
