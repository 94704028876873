import loadable from '@loadable/component';
import React, { useContext, useEffect, useState } from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

import {
  Divider,
  MenuItem,
  Pagination,
  Select,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import {
  fetchStrategiesByOutcome,
  selectStrategiesByOutcome,
} from '../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';

import StrategyCard from '../cards/StrategyCard';
export default function StrategyList({
  outcomeId,
  isLoggedIn,
  setListLoaded
}) {
  const { userData } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const dispatch = useDispatch();
  const strategyStatus = useSelector(
    (state) => state.outcomes.outcomes.entities[outcomeId].strategyStatus
  );
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const allStrategies = useSelector((state) =>
    selectStrategiesByOutcome(state, outcomeId)
  );

  const LoadableQuestionCard = loadable(() => import('../cards/QuestionCard'), {
    fallback: <ClipLoader color="#f87171" loading={true} size={50} /> // Loading indicator
  });

  const LoadableCommentList = loadable(() => import('./CommentList'), {
    fallback: <ClipLoader color="#f87171" loading={true} size={50} /> // Loading indicator
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    function fetchStrategiesData() {
      if (isMounted) {
        if (strategyStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchStrategiesByOutcome({
              outcomeId: outcomeId,
              auth_token: token
            })
          );
        }
      }
    }

    fetchStrategiesData();
    return () => {
      isMounted = false;
    };
  }, [strategyStatus, dispatch, outcomeId]);

  useEffect(() => {
    if (strategyStatus === 'succeeded') {
      setListLoaded(true);
    }
  }, [strategyStatus, setListLoaded]);

  const [content, setContent] = useState();

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    if (strategyStatus === 'loading') {
      setContent(
        <section className="strategy-list">
          <div className="text-center">
            <ClipLoader color="#f87171" loading={true} size={100} />
          </div>
        </section>
      );
    } else if (strategyStatus === 'succeeded') {
      const calculatedMaxPages = Math.ceil(allStrategies.length / pageSize);
      setMaxPages(calculatedMaxPages);

      setContent(
        <section className="strategy-list">
          <div className="flex relative mt-5 items-center mx-10">
            <Divider className="flex-grow"></Divider>
            <Typography sx={{ mx: 2.4 }} className="flex-shrink">
              Strategies
            </Typography>
            <Divider className="flex-grow"></Divider>
          </div>
          {allStrategies
            .slice(startIndex, endIndex)
            .map((strategy, index) => (
              <div key={index}>
                <StrategyCard
                  strategy={strategy}
                  isLoggedIn={isLoggedIn}
                />
                <LoadableCommentList
                  strategy={strategy}
                  maxIndentLevels={2}
                />
              </div>
            ))}
          {maxPages > 0 && (
            <div className="flex justify-center items-center my-1">
              <Pagination
                count={maxPages}
                page={page}
                onChange={handlePageChange}
              />
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                displayEmpty
                style={{ fontSize: '14px' }}>
                {Array.from({ length: 10 }, (_, index) => index + 1).map(
                  (size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
          )}
        </section>
      );
    } else {
      setContent(
        <section className="question-list">
          <Typography sx={{ my: 3 }} className="text-sm text-center">
            No strategies could be found.
          </Typography>
        </section>
      );
    }
  }, [
    strategyStatus,
    page,
    pageSize,
    maxPages,
    userPreferences.question_layout
  ]);

  return <div>{content}</div>;
}
